var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',[_c('v-container',{staticClass:"p-3 pt-1"},[_c('v-card-title',{staticClass:"p-0"},[_c('span',{staticClass:"primary--text"},[_vm._v(_vm._s(_vm.title))])]),_c('v-form',{ref:"select-origen",attrs:{"id":"select-origen"},on:{"submit":function($event){$event.preventDefault();return _vm.selectOrigen()}},model:{value:(_vm.isFormValid),callback:function ($$v) {_vm.isFormValid=$$v},expression:"isFormValid"}},[_c('v-row',{directives:[{name:"show",rawName:"v-show",value:(_vm.busquedaOrigen),expression:"busquedaOrigen"}]}),_c('v-row',{staticClass:"p-3 pb-0",on:{"keypress":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.filterOrigenes($event)}}},[_c('v-col',{staticClass:"py-0 px-1",attrs:{"cols":"6","sm":"12","md":"3"}},[_c('v-text-field',{attrs:{"label":"Nombre","dense":"","hide-details":"auto","id":"nombre-busqueda-origen","clearable":"","rules":[
            _vm.rules.requiredTrim(_vm.origenSearchNombre),
            _vm.rules.maxLength(_vm.origenSearchNombre, 15)
          ],"outlined":""},model:{value:(_vm.origenSearchNombre),callback:function ($$v) {_vm.origenSearchNombre=$$v},expression:"origenSearchNombre"}})],1),_c('v-col',{staticClass:"py-0 px-1",attrs:{"cols":"6","sm":"12","md":"3"}},[_c('v-text-field',{directives:[{name:"mask",rawName:"v-mask",value:([ '##-#######-#', '##-########-#']),expression:"[ '##-#######-#', '##-########-#']"}],attrs:{"label":"CUIT","dense":"","hide-details":"auto","clearable":"","id":"cuit-busqueda-origen","rules":[
            _vm.rules.requiredTrim(_vm.origenSearchCuit),
            _vm.rules.minLength(_vm.origenSearchCuit, 7)
          ],"outlined":""},model:{value:(_vm.origenSearchCuit),callback:function ($$v) {_vm.origenSearchCuit=$$v},expression:"origenSearchCuit"}})],1),_c('v-col',{staticClass:"py-0 px-1",attrs:{"cols":"6","sm":"12","md":"3"}},[_c('v-text-field',{directives:[{name:"mask",rawName:"v-mask",value:('##########'),expression:"'##########'"}],attrs:{"type":"text","label":"Código","id":"codigo-origen-busqueda","dense":"","hide-details":"auto","rules":[
            _vm.rules.requiredTrim(_vm.origenSearchCodigo)
          ],"clearable":"","outlined":""},model:{value:(_vm.origenSearchCodigo),callback:function ($$v) {_vm.origenSearchCodigo=$$v},expression:"origenSearchCodigo"}})],1),_c('v-col',{staticClass:"py-0 text-right",attrs:{"cols":"12","md":"3"}},[_c('v-btn',{staticClass:"to-right",attrs:{"color":"primary","disabled":!_vm.isFormValid},on:{"click":_vm.filterOrigenes}},[_vm._v(" Buscar ")])],1)],1),_c('v-row',[_c('v-col',{staticClass:"py-0 pb-2",attrs:{"cols":"12"}},[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.origenesResult,"loading":_vm.loading,"id":"tabla-origenes-busqueda","dense":"","show-select":"","item-key":"oriId","single-select":""},on:{"input":function($event){return _vm.selectedOrigen()}},model:{value:(_vm.origenSelected),callback:function ($$v) {_vm.origenSelected=$$v},expression:"origenSelected"}})],1)],1),_c('v-card-actions',{staticClass:"py-0"},[_c('v-spacer'),_c('v-btn',{attrs:{"outlined":""},on:{"click":function($event){return _vm.closeModal()}}},[_vm._v(" Cerrar ")]),_c('v-btn',{ref:"select-origen",attrs:{"color":"primary","type":"submit","disabled":_vm.origenSelected.length == 0}},[_vm._v(" Seleccionar ")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }