<template>
  <v-card>
    <v-container class="p-3 pt-1">
      <v-card-title class="p-0">
        <span class="primary--text">{{ title }}</span>
      </v-card-title>
      <v-form
        v-model="isFormValid"
        ref="select-origen"
        id="select-origen"
        @submit.prevent="selectNom()"
      >
        <v-row v-show="busquedaNom"> </v-row>
        <v-row class="p-3 pb-0" @keypress.enter="filterNomencladores">
          <v-col cols="6" sm="12" md="3" class="py-0 px-1">
            <v-text-field
              v-model="nomSearchNombre"
              label="Nombre"
              dense
              hide-details="auto"
              id="nombre-busqueda-nomenclador"
              clearable
              :rules="[
                rules.requiredTrim(nomSearchNombre),
                rules.maxLength(nomSearchNombre, 15)
              ]"
              outlined
            >
            </v-text-field>
          </v-col>

          <v-col cols="6" sm="12" md="3" class="py-0 px-1">
            <v-text-field
              v-model="nomSearchCodigo"
              type="text"
              label="Código"
              id="codigo-nomenclador-busqueda"
              dense
              hide-details="auto"
              v-mask="'##########'"
              :rules="[rules.requiredTrim(nomSearchCodigo)]"
              clearable
              outlined
            >
            </v-text-field>
          </v-col>
          <v-col cols="12" md="3" class="py-0 text-right">
            <v-btn
              color="primary"
              class="to-right"
              :disabled="!isFormValid"
              @click="filterNomencladores"
            >
              Buscar
            </v-btn>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12" class="py-0 pb-2">
            <v-data-table
              class="elevation-1"
              :headers="headers"
              :items="nomencladoresResult"
              :loading="loading"
              id="tabla-nomencladores-busqueda"
              dense
              show-select
              item-key="index"
              single-select
              v-model="nomencladorSelected"
              @input="selectedNomenclador()"
            >
            </v-data-table>
          </v-col>
        </v-row>
        <v-card-actions class="py-0">
          <v-spacer></v-spacer>
          <v-btn outlined @click="closeModal()">
            Cerrar
          </v-btn>
          <v-btn
            color="primary"
            type="submit"
            ref="select-origen"
            :disabled="nomencladorSelected.length == 0"
          >
            Seleccionar
          </v-btn>
        </v-card-actions>
      </v-form>
    </v-container>
  </v-card>
</template>

<script>
import rules from "@/utils/helpers/rules";
import { mapActions } from "vuex";
import { mask } from "vue-the-mask";

export default {
  name: "BusquedaNomencladores",
  props: {},
  directives: { mask },
  data() {
    return {
      title: "Búsqueda de nomencladores",
      rules: rules,
      isFormValid: false,
      filtersApplyed: [],
      nomencladorSelected: [],
      nomencladoresResult: [],
      dataTableHeight: null,
      nomCod: null,
      origenSearchCuit: null,
      nomSearchCodigo: null,
      nomSearchNombre: null,
      filterApplied: false,
      banderaNomSelected: false,
      busquedaNom: true,
      loading: false,
      headers: [
        {
          text: "Tipo nomenclador",
          sortable: false,
          value: "value2"
        },
        {
          text: "Código",
          sortable: false,
          value: "id"
        },
        {
          text: "Nombre de práctica",
          align: "start",
          sortable: true,
          value: "value"
        }
      ]
    };
  },
  watch: {
    nomencladoresResult(val) {
      if (val.length >= 4) {
        this.dataTableHeight = "10rem";
      } else if (val.length >= 2) {
        this.dataTableHeight = "7rem";
      } else {
        this.dataTableHeight = "5rem";
      }
    }
  },
  methods: {
    ...mapActions({
      getNomencladoresByCodAndNom: "prestadores/getNomencladoresByCodAndNom",
      setAlert: "user/setAlert"
    }),
    selectNom() {
      this.$emit("toggleModalBusquedaNom");
      this.$emit("findNomenclador", this.nomencladorSelected[0]);
    },
    closeModal() {
      this.$emit("toggleModalBusquedaNom");
    },
    // metodos grupos familiares filter
    async filterNomencladores() {
      if (
        this.nomSearchNombre != null ||
        this.origenSearchCuit != null ||
        this.nomSearchCodigo != null
      ) {
        this.loading = true;
        this.customizeFiltersApplied();
        const data = {
          nomCod: this.nomSearchCodigo != null ? this.nomSearchCodigo : "",
          nombre: this.nomSearchNombre != null ? this.nomSearchNombre : ""
        };
        try {
          this.filterApplied = true;
          const response = await this.getNomencladoresByCodAndNom(data);
          this.nomencladoresResult = response.map((nom, index) => {
            return {
              ...nom,
              index: index + 1
            };
          });
          this.banderaNomSelected
            ? (this.nomencladorSelected = this.nomencladoresResult)
            : [];
          this.loading = false;
        } catch {
          this.loading = false;
        }
      } else {
        this.setAlert({
          type: "warning",
          message: "Ingrese al menos un parámetro de búsqueda"
        });
      }
    },
    customizeFiltersApplied() {
      this.filtersApplyed = [];

      if (this.nomencladorSelected.length > 0) {
        this.filtersApplyed.splice(0, 1, {
          key: "value",
          label: "Nombre",
          model: this.nomencladorSelected[0].nombre.toUpperCase()
        });
      } else {
        if (this.nomSearchNombre) {
          this.filtersApplyed.splice(1, 1, {
            key: "nombre",
            label: "Nombre",
            model: this.nomSearchNombre
          });
        }

        if (this.nomSearchCodigo) {
          this.filtersApplyed.splice(3, 1, {
            key: "codigo",
            label: "Código",
            model: this.nomSearchCodigo.toUpperCase()
          });
        }
      }
    },
    selectedNomenclador() {
      if (this.nomencladorSelected.length > 0) {
        this.nomCod = this.nomencladorSelected[0].id;
        this.banderaNomSelected = false;
      }
    }
  }
};
</script>

<style scoped>
::v-deep .theme--light.v-input input {
  color: rgb(56, 56, 56);
}
::v-deep .v-input__slot {
  cursor: context-menu !important;
}
</style>
