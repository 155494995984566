<template>
  <v-card>
    <v-container class="p-3 pt-1">
      <v-card-title class="p-0">
        <span class="primary--text">{{ title }}</span>
      </v-card-title>
      <v-form
        v-model="isFormValid"
        ref="select-origen"
        id="select-origen"
        @submit.prevent="selectOrigen()"
      >
      <v-row v-show="busquedaOrigen"> </v-row>
      <v-row class="p-3 pb-0" @keypress.enter="filterOrigenes">
        <v-col cols="6" sm="12" md="3" class="py-0 px-1">
          <v-text-field
            v-model="origenSearchNombre"
            label="Nombre"
            dense
            hide-details="auto"
            id="nombre-busqueda-origen"
            clearable
            :rules="[
              rules.requiredTrim(origenSearchNombre),
              rules.maxLength(origenSearchNombre, 15)
            ]"
            outlined
          >
          </v-text-field>
        </v-col>
        <v-col cols="6" sm="12" md="3" class="py-0 px-1">
          <v-text-field
            v-model="origenSearchCuit"
            label="CUIT"
            dense
            v-mask="[ '##-#######-#', '##-########-#']"
            hide-details="auto"
            clearable
            id="cuit-busqueda-origen"
            :rules="[
              rules.requiredTrim(origenSearchCuit),
              rules.minLength(origenSearchCuit, 7)
            ]"
            outlined
          >
          </v-text-field>
        </v-col>
        
        <v-col cols="6" sm="12" md="3" class="py-0 px-1">
          <v-text-field
            v-model="origenSearchCodigo"
            type="text"
            label="Código"
            id="codigo-origen-busqueda"
            dense
            hide-details="auto"
            v-mask="'##########'"
            :rules="[
              rules.requiredTrim(origenSearchCodigo)
            ]"
            clearable
            outlined
          >
          </v-text-field>
        </v-col>
        <v-col cols="12" md="3" class="py-0 text-right">
          <v-btn color="primary" class="to-right" :disabled="!isFormValid" @click="filterOrigenes">
            Buscar
          </v-btn>
        </v-col>
      </v-row>

      
        <v-row>
          <v-col cols="12" class="py-0 pb-2">
            <v-data-table
              class="elevation-1"
              :headers="headers"
              :items="origenesResult"
              :loading="loading"
              id="tabla-origenes-busqueda"
              dense
              show-select
              item-key="oriId"
              single-select
              v-model="origenSelected"
              @input="selectedOrigen()"
            >
            </v-data-table>
          </v-col>
        </v-row>
        <v-card-actions class="py-0">
          <v-spacer></v-spacer>
          <v-btn outlined @click="closeModal()">
            Cerrar
          </v-btn>
          <v-btn
            color="primary"
            type="submit"
            ref="select-origen"
            :disabled="origenSelected.length == 0"
          >
            Seleccionar
          </v-btn>
        </v-card-actions>
      </v-form>
    </v-container>
  </v-card>
</template>

<script>
import rules from "@/utils/helpers/rules";
import { mapActions } from "vuex";
import { mask } from "vue-the-mask";

export default {
  name: "BusquedaOrigenes",
  props: {},
  directives: { mask },
  data() {
    return {
      title: "Búsqueda de orígenes",
      rules: rules,
      isFormValid: false,
      filtersApplyed: [],
      origenSelected: [],
      origenesResult: [],
      dataTableHeight: null,
      oriId: null,
      origenSearchCuit: null,
      origenSearchCodigo: null,
      origenSearchNombre: null,
      filterApplied: false,
      banderaOrigenSelected: false,
      busquedaOrigen: true,
      loading: false,
      headers: [
        {
          text: "Código",
          sortable: false,
          value: "oriId"
        },
        {
          text: "Origen",
          align: "start",
          sortable: true,
          value: "nombre"
        },
        {
          text: "CUIT",
          sortable: false,
          value: "cuit"
        },
        
      ]
    };
  },
  watch: {
    origenesResult(val) {
      if (val.length >= 4) {
        this.dataTableHeight = "10rem";
      } else if (val.length >= 2) {
        this.dataTableHeight = "7rem";
      } else {
        this.dataTableHeight = "5rem";
      }
    }
  },
  methods: {
    ...mapActions({
      getOrigenesByCuitNombreCodigo: "prestadores/getOrigenesByCuitNombreCodigo",
      setAlert: "user/setAlert"
    }),
    selectOrigen() {
      this.$emit("toggleModalBusquedaOri");
      this.$emit("findOrigen", this.origenSelected[0]);
    },
    closeModal() {
      this.$emit("toggleModalBusquedaOri");
    },
    // metodos grupos familiares filter
    async filterOrigenes() {
      if (
        this.origenSearchNombre != null ||
        this.origenSearchCuit != null ||
        this.origenSearchCodigo != null
      ) {
        this.loading = true;
        this.customizeFiltersApplied();
        const data = {
          codigo: this.origenSearchCodigo != null ? this.origenSearchCodigo : "",
          cuit: this.origenSearchCuit != null ? this.origenSearchCuit.replaceAll("-", "") : "",
          nombre: this.origenSearchNombre != null ? this.origenSearchNombre : ""
        };
        try {
          this.filterApplied = true;
          const response = await this.getOrigenesByCuitNombreCodigo(data);
          this.origenesResult = response;
          this.banderaOrigenSelected
            ? (this.origenSelected = this.origenesResult)
            : [];
          this.loading = false;
        } catch {
          this.loading = false;
        }
      } else {
        this.setAlert({
          type: "warning",
          message: "Ingrese al menos un parámetro de búsqueda"
        });
      }
    },
    customizeFiltersApplied() {
      this.filtersApplyed = [];

      if (this.origenSelected.length > 0) {
        this.filtersApplyed.splice(0, 1, {
          key: "value",
          label: "Origen",
          model: this.origenSelected[0].nombre.toUpperCase()
        });
      } else {
        if (this.origenSearchCuit) {
          this.filtersApplyed.splice(0, 1, {
            key: "cuit",
            label: "CUIT",
            model: this.origenSearchCuit
          });
        }
        if (this.origenSearchNombre) {
          this.filtersApplyed.splice(1, 1, {
            key: "nombre",
            label: "Nombre",
            model: this.origenSearchNombre
          });
        }
      
        if (this.origenSearchCodigo) {
          this.filtersApplyed.splice(3, 1, {
            key: "codigo",
            label: "Código",
            model: this.origenSearchCodigo.toUpperCase()
          });
        }
      }
    },
    selectedOrigen() {
      if (this.origenSelected.length > 0) {
        this.oriId = this.origenSelected[0].oriId;
        this.banderaOrigenSelected = false;
      }
    }
  }
};
</script>

<style scoped>
::v-deep .theme--light.v-input input {
  color: rgb(56, 56, 56);
}
::v-deep .v-input__slot {
  cursor: context-menu !important;
}
</style>
