<template>
  <v-card>
    <v-container class="py-0">
      <v-row>
        <v-col cols="11" class="py-0 pl-5">
          <v-card-title class="pl-1 p-0 primary--text"
            >Detalle del proceso N° {{ procId }} - Origen: {{ origenNomCod }}
          </v-card-title>
        </v-col>
        <v-col cols="1" class="py-0 pr-2">
          <v-tooltip left>
            <template v-slot:activator="{ on, attrs }">
              <v-btn class="to-right" icon @click="closeModalVerDetalle()">
                <v-icon v-bind="attrs" v-on="on">{{ closeIcon }}</v-icon>
              </v-btn>
            </template>
            <span>Cerrar</span>
          </v-tooltip>
        </v-col>
      </v-row>
      <v-data-table
        dense
        :headers="headers"
        :items="registros"
        :search="search"
        id="data-table-detalle-proceso-importacion-origen-traductor"
        hide-default-header
        item-key="origenTraId"
        class="custom-table"
        :loading="isLoading"
        :expanded.sync="expanded"
      >
        <template v-slot:top>
          <v-container>
            <v-row>
              <v-col cols="12" md="6" class="py-1 pb-2 pl-8">
                <v-text-field
                  v-model="search"
                  :append-icon="searchIcon"
                  label="Buscar"
                  dense
                  id="search-detalle-proceso-importacion-origen-traductor"
                  single-line
                  hide-details
                ></v-text-field>
              </v-col>
              <v-col cols="6" class="py-1 pb-2 pl-8 text-right">
                <v-btn
                  color="primary"
                  class="to-right py-4"
                  small
                  id="btn-exportar-detalle-proceso-importacion-origen-traductor"
                  outlined
                  :loading="loadingExportarExcel"
                  :disabled="registros.length == 0 || loadingExportarExcel"
                  @click="exportarExcel()"
                >
                  Exportar detalle
                </v-btn>
              </v-col>
            </v-row>
          </v-container>
        </template>
        <template v-slot:header="{ props }">
          <thead class="p-0">
            <tr class="p-0">
              <th colspan="2" class="py-0">Datos de Gecros</th>
              <th
                colspan="2"
                class="py-0"
                style="border-left: 1px solid #939393"
              >
                Datos del Origen
              </th>
            </tr>
            <tr>
              <th
                v-for="header in props.headers"
                :key="header.text"
                :class="{
                  'with-divider': header.value === 'codigoPractica'
                }"
              >
                {{ header.text }}
              </th>
            </tr>
          </thead>
        </template>
     
        <template v-slot:[`item`]="{ item, isExpanded }">
          <tr>
            <td>{{ item.nomCod }}</td>
            <td class="nombre-practica-item">{{ item.nombre }}</td>
            <td class="with-divider">{{ item.codigoPractica }}</td>
           
            <td class="nombre-practica-item">
              {{ item.nombrePractica }}
            </td>
            <!-- Columna de acciones -->
            <td>
              <v-tooltip left>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    v-bind="attrs"
                    v-on="on"
                    small
                    @click="verRegistro(item)"
                  >
                    {{ seeIcon }}
                  </v-icon>
                </template>
                <span>Ver</span>
              </v-tooltip>
              <v-tooltip left>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    @click="handleExpansion(item, isExpanded)"
                    v-bind="attrs"
                    small
                    v-on="on"
                    >{{
                      isExpanded ? "mdi-account-check" : "mdi-account"
                    }}</v-icon
                  >
                </template>
                <span>Usuarios de carga y modificación</span>
              </v-tooltip>
            </td>
          </tr>
        </template>

        <template v-slot:expanded-item="{ headers, item }">
          <td :colspan="headers.length">
            <v-row class="fondoExpandable">
              <v-col cols="12" class="py-0 pl-2">
                <strong class="expandableInfoTitle">Fecha alta: </strong>
                <span class="expandableInfoText">{{ item.fecAlta }}</span>
                <strong class="expandableInfoTitle">Usuario alta: </strong>
                <span class="expandableInfoText">{{ item.usuAlta }}</span>
                <strong class="expandableInfoTitle"
                  >Fecha modificación:
                </strong>
                <span class="expandableInfoText">{{
                  !item.fecModi ? "Sin datos." : item.fecModi
                }}</span>
                <strong class="expandableInfoTitle"
                  >Usuario modificación:
                </strong>
                <span class="expandableInfoText">{{
                  item.usuModi != null ? item.usuModi : "Sin datos."
                }}</span>
              </v-col>
              
            </v-row>
          </td>
        </template>
      </v-data-table>
    </v-container>
    <v-dialog
      v-model="modalVerTraductor"
      v-if="modalVerTraductor"
      @keydown.esc="toggleModalVerTraductor"
      max-width="55rem"
      persistent
    >
      <NuevoTraductorCodigoNomenclador
        @toggleModalNuevoTraductor="toggleModalVerTraductor"
        :origenTraItem="origenTraItem"
        :disableEdit="true"
      ></NuevoTraductorCodigoNomenclador>
    </v-dialog>
  </v-card>
</template>
<script>
import { mapActions } from "vuex";
import enums from "@/utils/enums/index";
import NuevoTraductorCodigoNomenclador from "@/components/modules/prestadores/NuevoTraductorCodigoNomenclador.vue";

export default {
  name: "DetalleProcesoImportacionOrigenTraductor",
  components: {
    NuevoTraductorCodigoNomenclador
  },
  props: {
    procId: {
      type: Number,
      required: false
    },
    origen: {
      type: String,
      required: false,
      default: ""
    }
  },
  data: () => ({
    isLoading: false,
    modalVerTraductor: false,
    registros: [],
    origenNomCod: "",
    searchIcon: enums.icons.SEARCH,
    closeIcon: enums.icons.CLOSE,
    chevronUpIcon: enums.icons.CHEVRON_UP,
    chevronDownIcon: enums.icons.CHEVRON_DOWN,
    seeIcon: enums.icons.SEE,
    search: "",
    loadingExportarExcel: false,
    expanded: [],
    headers: [
     
      {
        text: "Código práctica ",
        value: "nomCod",
        align: "start"
      },
      { text: "Nombre de práctica ", value: "nombre", align: "start" },

      // {
      //   text: "Código tipo nomenclador",
      //   value: "codigoTipoNomenclador"
      // },
      // {
      //   text: "Nombre tipo nomenclador",
      //   value: "nombreTipoNomenclador"
      // },
      { text: "Código práctica", align: "start", value: "codigoPractica" },
      { text: "Nombre de práctica", align: "start", value: "nombrePractica" },
      // { text: "Área", value: "area" },
      // { text: "Tipo nomenclador", value: "tipoNom" },
      { text: "Acciones", value: "actions", align: "end", sortable: false }
    ]
  }),
  created() {
    this.$store.dispatch("user/changeAppLoading", {
      status: false,
      text: "",
      opaque: false
    });
    this.origenNomCod = this.origen;
    this.setDetalleProcOrigenTraductor();
  },

  methods: {
    ...mapActions({
      getDetalleProcesoImportacionOrigenTraductor:
        "prestadores/getDetalleProcesoImportacionOrigenTraductor",
      exportarExcelOrigenTraductor: "prestadores/exportarExcelOrigenTraductor",
      setAlert: "user/setAlert"
    }),
    handleExpansion(item, state) {
      const itemIndex = this.expanded.indexOf(item);
      state ? this.expanded.splice(itemIndex, 1) : this.expanded.push(item);
    },
    verRegistro(item) {
      this.toggleModalVerTraductor(item);
    },
    toggleModalVerTraductor(item) {
      this.origenTraItem = item;
      this.modalVerTraductor = !this.modalVerTraductor;
    },
    async setDetalleProcOrigenTraductor() {
      this.isLoading = true;
      const data = await this.getDetalleProcesoImportacionOrigenTraductor(
        this.procId
      );
      this.registros = data;
      this.isLoading = false;
      if (this.registros.length < 1) {
        this.setAlert({
          type: "warning",
          message: "No hay actualizaciones para mostrar"
        });
      }
    },
    closeModalVerDetalle() {
      this.$emit("closeModalVerDetalle");
    },
    cleanChar(str, char) {
      while (true) {
        var result_1 = str.replace(char, "");
        if (result_1 === str) {
          break;
        }
        str = result_1;
      }
      return str;
    },
    async exportarExcel() {
      this.loadingExportarExcel = true;
      try {
        const data = {
          oriId: null,
          procId: this.procId
        };
        const response = await this.exportarExcelOrigenTraductor(data);
        if (response.status === 200) {
          this.setAlert({
            message: "Exportación realizada correctamente",
            type: "success"
          });
        }
      } catch (error) {
      } finally {
        this.loadingExportarExcel = false;
      }
    }
  }
};
</script>

<style scoped>
.with-divider {
  border-left: 1px solid #939393;
  padding-left: 10px;
}
.expandableInfoTitle {
  font-size: 0.8rem;
  padding-left: 1rem;
}
.expandableInfoText {
  font-size: 0.7rem;
}
.fondoExpandable {
  background-color: #0000000c;
}
</style>
