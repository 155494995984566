var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',[_c('v-container',{staticClass:"py-0"},[_c('v-row',[_c('v-col',{staticClass:"py-0 pl-5",attrs:{"cols":"11"}},[_c('v-card-title',{staticClass:"pl-1 p-0 primary--text"},[_vm._v("Detalle del proceso N° "+_vm._s(_vm.procId)+" - Origen: "+_vm._s(_vm.origenNomCod)+" ")])],1),_c('v-col',{staticClass:"py-0 pr-2",attrs:{"cols":"1"}},[_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',{staticClass:"to-right",attrs:{"icon":""},on:{"click":function($event){return _vm.closeModalVerDetalle()}}},[_c('v-icon',_vm._g(_vm._b({},'v-icon',attrs,false),on),[_vm._v(_vm._s(_vm.closeIcon))])],1)]}}])},[_c('span',[_vm._v("Cerrar")])])],1)],1),_c('v-data-table',{staticClass:"custom-table",attrs:{"dense":"","headers":_vm.headers,"items":_vm.registros,"search":_vm.search,"id":"data-table-detalle-proceso-importacion-origen-traductor","hide-default-header":"","item-key":"origenTraId","loading":_vm.isLoading,"expanded":_vm.expanded},on:{"update:expanded":function($event){_vm.expanded=$event}},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-container',[_c('v-row',[_c('v-col',{staticClass:"py-1 pb-2 pl-8",attrs:{"cols":"12","md":"6"}},[_c('v-text-field',{attrs:{"append-icon":_vm.searchIcon,"label":"Buscar","dense":"","id":"search-detalle-proceso-importacion-origen-traductor","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-col',{staticClass:"py-1 pb-2 pl-8 text-right",attrs:{"cols":"6"}},[_c('v-btn',{staticClass:"to-right py-4",attrs:{"color":"primary","small":"","id":"btn-exportar-detalle-proceso-importacion-origen-traductor","outlined":"","loading":_vm.loadingExportarExcel,"disabled":_vm.registros.length == 0 || _vm.loadingExportarExcel},on:{"click":function($event){return _vm.exportarExcel()}}},[_vm._v(" Exportar detalle ")])],1)],1)],1)]},proxy:true},{key:"header",fn:function(ref){
var props = ref.props;
return [_c('thead',{staticClass:"p-0"},[_c('tr',{staticClass:"p-0"},[_c('th',{staticClass:"py-0",attrs:{"colspan":"2"}},[_vm._v("Datos de Gecros")]),_c('th',{staticClass:"py-0",staticStyle:{"border-left":"1px solid #939393"},attrs:{"colspan":"2"}},[_vm._v(" Datos del Origen ")])]),_c('tr',_vm._l((props.headers),function(header){return _c('th',{key:header.text,class:{
                'with-divider': header.value === 'codigoPractica'
              }},[_vm._v(" "+_vm._s(header.text)+" ")])}),0)])]}},{key:"item",fn:function(ref){
              var item = ref.item;
              var isExpanded = ref.isExpanded;
return [_c('tr',[_c('td',[_vm._v(_vm._s(item.nomCod))]),_c('td',{staticClass:"nombre-practica-item"},[_vm._v(_vm._s(item.nombre))]),_c('td',{staticClass:"with-divider"},[_vm._v(_vm._s(item.codigoPractica))]),_c('td',{staticClass:"nombre-practica-item"},[_vm._v(" "+_vm._s(item.nombrePractica)+" ")]),_c('td',[_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
              var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"small":""},on:{"click":function($event){return _vm.verRegistro(item)}}},'v-icon',attrs,false),on),[_vm._v(" "+_vm._s(_vm.seeIcon)+" ")])]}}],null,true)},[_c('span',[_vm._v("Ver")])]),_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
              var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"small":""},on:{"click":function($event){return _vm.handleExpansion(item, isExpanded)}}},'v-icon',attrs,false),on),[_vm._v(_vm._s(isExpanded ? "mdi-account-check" : "mdi-account"))])]}}],null,true)},[_c('span',[_vm._v("Usuarios de carga y modificación")])])],1)])]}},{key:"expanded-item",fn:function(ref){
              var headers = ref.headers;
              var item = ref.item;
return [_c('td',{attrs:{"colspan":headers.length}},[_c('v-row',{staticClass:"fondoExpandable"},[_c('v-col',{staticClass:"py-0 pl-2",attrs:{"cols":"12"}},[_c('strong',{staticClass:"expandableInfoTitle"},[_vm._v("Fecha alta: ")]),_c('span',{staticClass:"expandableInfoText"},[_vm._v(_vm._s(item.fecAlta))]),_c('strong',{staticClass:"expandableInfoTitle"},[_vm._v("Usuario alta: ")]),_c('span',{staticClass:"expandableInfoText"},[_vm._v(_vm._s(item.usuAlta))]),_c('strong',{staticClass:"expandableInfoTitle"},[_vm._v("Fecha modificación: ")]),_c('span',{staticClass:"expandableInfoText"},[_vm._v(_vm._s(!item.fecModi ? "Sin datos." : item.fecModi))]),_c('strong',{staticClass:"expandableInfoTitle"},[_vm._v("Usuario modificación: ")]),_c('span',{staticClass:"expandableInfoText"},[_vm._v(_vm._s(item.usuModi != null ? item.usuModi : "Sin datos."))])])],1)],1)]}}],null,true)})],1),(_vm.modalVerTraductor)?_c('v-dialog',{attrs:{"max-width":"55rem","persistent":""},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"esc",27,$event.key,["Esc","Escape"])){ return null; }return _vm.toggleModalVerTraductor($event)}},model:{value:(_vm.modalVerTraductor),callback:function ($$v) {_vm.modalVerTraductor=$$v},expression:"modalVerTraductor"}},[_c('NuevoTraductorCodigoNomenclador',{attrs:{"origenTraItem":_vm.origenTraItem,"disableEdit":true},on:{"toggleModalNuevoTraductor":_vm.toggleModalVerTraductor}})],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }