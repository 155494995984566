<template>
  <v-card class="p-0">
    <v-form
      v-model="isFormValid"
      ref="nuevo-proceso-traductor-codigo-nomenclador"
      id="nuevo-proceso-traductor-codigo-nomenclador"
      @submit.prevent="grabarArchivo()"
    >
      <v-card-title class="text-h6 primary--text"
        >Nuevo proceso de importación</v-card-title
      >
      <v-card-text class="py-0">
        <v-container class="p-0">
          <v-row
            ><v-col cols="12" md="3" class="py-0 pr-0">
              <v-text-field
                label="Código de Origen"
                v-model.trim="codigoOrigen"
                dense
                v-mask="'##########'"
                @change="findOrigenById()"
                hide-details="auto"
                :rules="rules.required"
                :loading="loadingOrigenById"
                outlined
                id="codigo-origen-importacion-traductor"
              >
                <template v-slot:append-outer>
                  <v-tooltip top max-width="50%">
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                        color="primary"
                        v-bind="attrs"
                        id="btn-origen-importacion-traductor"
                        hide-details="auto"
                        v-on="on"
                        tabindex="-1"
                        @click="toggleModalBusquedaOri()"
                      >
                        {{ searchIcon }}
                      </v-icon>
                    </template>
                    <span>Buscar Origen por nombre, CUIT o código</span>
                  </v-tooltip>
                </template>
              </v-text-field>
            </v-col>
            <!-- DATOS ORIGEN -->

            <v-col cols="12" md="3" class="p-0 pl-1">
              <v-text-field
                type="text"
                outlined
                dense
                id="origen-importacion-traductor"
                filled
                disabled
                :rules="rules.required"
                readonly
                label="Origen"
                hide-details="auto"
                v-model.trim="origenSelectedNom"
                autocomplete="off"
              >
              </v-text-field>
            </v-col>
            <!-- Input para cargar archivo Excel -->
            <v-col cols="12" md="4" class="p-0">
              <v-file-input
                id="file-input-excel"
                label="Seleccionar archivo Excel"
                v-model="excelFile"
                hide-details="auto"
                accept=".xls, .xlsx"
                dense
                small
                outlined
                @change="procesarArchivo()"
              ></v-file-input>
            </v-col>
            <v-col cols="12" md="2" class="pb-0 pt-1 px-0 text-right">
              <v-btn
                id="btn-ver-formato"
                hide-details="auto"
                color="info"
                outlined
                @click="openModalSeeFormatoArchivo()"
                >Ver Formato</v-btn
              >
            </v-col>
          </v-row>
          <!-- Vista previa de la tabla -->
          <v-row>
            <v-col cols="12" class="py-0">
              <v-data-table
                id="data-table-preview"
                :headers="headers"
                :search="search"
                :items="excelData"
                :loading="loadingValidacionArchivo"
                loading-text="Procesando archivo..."
                dense
                outlined
                class="mt-4"
              >
                <template v-slot:top>
                  <v-row>
                    <v-col cols="12" md="6" class="pt-0 pl-8">
                      <v-text-field
                        v-model="search"
                        :append-icon="searchIcon"
                        label="Buscar"
                        dense
                        id="search-traductor-codigo-importacion"
                        single-line
                        hide-details
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </template>
                <template v-slot:no-data>
                  No hay datos para mostrar. Por favor, importe un archivo
                  Excel.
                </template>
              </v-data-table>
            </v-col>
            <!-- Botones de acción -->
            <!-- <v-col cols="12" md="12" class="text-right py-0">
            <v-btn
              id="btn-cancelar"
              outlined
              color="primary"
              small
              @click="cancelar"
              >Cancelar</v-btn
            >
            <v-btn
              id="btn-grabar"
              color="primary"
              small
              @click="grabarArchivo"
              :disabled="!excelFile"
              >Grabar</v-btn
            >
          </v-col> -->
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions class="pt-0">
        <v-spacer></v-spacer>
        <v-btn
          id="btn-cancelar"
          color="primary"
          small
          outlined
          @click="cancelar"
          >Cancelar</v-btn
        >
        <v-btn
          id="btn-grabar"
          color="primary"
          type="submit"
          form="nuevo-proceso-traductor-codigo-nomenclador"
          small
          :disabled="
             loadingValidacionArchivo || excelData.length === 0 || !isFormValid
          "
          >Grabar</v-btn
        >
      </v-card-actions>
    </v-form>
    <v-dialog
      v-model="modalBusquedaOrigen"
      v-if="modalBusquedaOrigen"
      @keydown.esc="toggleModalBusquedaOri"
      max-width="60rem"
      persistent
    >
      <BusquedaOrigenes
        @toggleModalBusquedaOri="toggleModalBusquedaOri"
        @findOrigen="findOrigen"
      ></BusquedaOrigenes>
    </v-dialog>
    <FormatoArchivoDialog
      :formatoTitle="formatoTitle"
      :headers="headersFormato"
      :datosFormatoArchivo="datosFormatoArchivo"
      :esProcesoOrigenTraductor="true"
      :openFormatoArchivoDialog.sync="showSeeFormatModal"
    />
  </v-card>
</template>

<script>
import { mapActions } from "vuex";
import BusquedaOrigenes from "@/components/modules/prestadores/BusquedaOrigenes.vue";
import enums from "@/utils/enums/index.js";
import rules from "@/utils/helpers/rules";
import FormatoArchivoDialog from "@/components/shared/FormatoArchivoDialog.vue";
import { mask } from "vue-the-mask";

export default {
  name: "NuevoProcesoImportacionOrigenTraductor",
  components: {
    BusquedaOrigenes,
    FormatoArchivoDialog
  },
  props: {
    routeParams: {
      type: Object,
      required: false,
      default: null
    }
  },
  directives: { mask },
  data() {
    return {
      searchIcon: enums.icons.SEARCH,
      search: null,
      isFormValid: false,
      modalBusquedaOrigen: false,
      formatoTitle: "Formato de archivo",
      datosFormatoArchivo: [],
      showSeeFormatModal: false,
      codigoOrigen: "",
      origenSelectedNom: null,
      oriId: null,
      sesionId: null,
      origenSelectedCuit: null,
      rules: rules,
      loadingOrigenById: false,
      loadingValidacionArchivo: false,
      excelFile: null,
      excelData: [],
      headers: [
        { text: "Origen Tipo Nom Cod", value: "origenTipoNomCod" },
        { text: "Origen Tipo Nom Nom", value: "origenTipoNomNom" },
        { text: "Origen Práctica Cod", value: "origenPracticaCod" },
        { text: "Origen Práctica Nom", value: "origenPracticaNom" },
        { text: "Área", value: "area" },
        { text: "Tipo Nom", value: "tipoNom" },
        { text: "Código", value: "codigo" },
        { text: "Práctica", value: "practica" }
      ],
      headersFormato: [
        {
          text: "Nombre campo",
          align: "start",
          sortable: false,
          value: "nombreCampo"
        },
        {
          text: "Longitud máxima",
          align: "start",
          sortable: false,
          value: "longMax"
        },
        {
          text: "Formato",
          value: "formato",
          align: "start",
          sortable: false
        },
        {
          text: "Requerido",
          value: "requerido",
          align: "center",
          sortable: false
        },
        {
          text: "Descripción",
          value: "descripcion",
          align: "start",
          sortable: false
        },
        {
          text: "Ejemplo",
          value: "datoEjemplo",
          align: "start",
          sortable: false
        }
      ]
    };
  },
  created() {
    if (this.routeParams) {
      this.findOrigen(this.routeParams);
    }
  },
  methods: {
    ...mapActions({
      exportarFormatoImportacionOrigenTraductor:
        "prestadores/exportarFormatoImportacionOrigenTraductor",
      getOrigenById: "prestadores/getOrigenById",
      grabarProcesoImportacionOrigenTraductor:
        "prestadores/grabarProcesoImportacionOrigenTraductor",
      validaImportacionOrigenTraductor:
        "prestadores/validaImportacionOrigenTraductor",
      getFormatoImportacionProcesoOrigenTraductor:
        "prestadores/getFormatoImportacionProcesoOrigenTraductor",
      setAlert: "user/setAlert"
    }),
    async procesarArchivo() {
      if (!this.excelFile) return;
      try {
        this.loadingValidacionArchivo = true;
        const data = {
          archivo: this.excelFile,
          oriId: this.oriId
        };
        const response = await this.validaImportacionOrigenTraductor(data);
        if (response) {
          this.excelData = response.traductorRegistros;
          this.sesionId = response.sesionId;
          this.setAlert({
            message: "Archivo validado correctamente.",
            type: "success"
          });
        } else {
          this.setAlert({
            message:
              "Se encontraron errores en la importación, a continuación se exportará un archivo.",
            type: "error"
          });
        }
      } catch (error) {
      } finally {
        this.loadingValidacionArchivo = false;
      }
    },
    async openModalSeeFormatoArchivo() {
      const formatoProceso = await this.getFormatoImportacionProcesoOrigenTraductor();
      this.datosFormatoArchivo = formatoProceso;
      this.showSeeFormatModal = true;
    },
    async verFormato() {
      try {
        const response = await this.exportarFormatoImportacionOrigenTraductor();
        if (response) {
          this.setAlert({
            message: "Archivo exportado correctamente.",
            type: "success"
          });
        }
      } catch (error) {}
    },
    async grabarArchivo() {
      const data = {
        sesionId: this.sesionId,
        oriId: this.oriId,
        nombreArchivo: this.excelFile.name
      };
      const response = await this.grabarProcesoImportacionOrigenTraductor(data);
      if (response) {
        this.setAlert({
          message: "Proceso de importación realizado correctamente.",
          type: "success"
        });
        this.$emit("toggleModalProcesoImportacion");
      }
    },
    async findOrigenById() {
      if (!this.codigoOrigen) return;
      try {
        this.loadingOrigenById = true;
        const origenResponse = await this.getOrigenById(this.codigoOrigen);
        if (origenResponse != null) {
          this.findOrigen(origenResponse);
        } else {
          this.origenSelectedNom = null;
          this.codigoOrigen = null;
          this.oriId = null;
          this.origenSelectedCuit = null;
          this.setAlert({
            message: "No se encontró el origen",
            type: "error"
          });
        }
      } catch (error) {
      } finally {
        this.loadingOrigenById = false;
      }
    },
    findOrigen(origenSelected) {
      this.origenSelectedNom = origenSelected.nombre;
      this.codigoOrigen = origenSelected.oriId;
      this.oriId = origenSelected.oriId;
      this.origenSelectedCuit = origenSelected.cuit;
    },
    toggleModalBusquedaOri() {
      this.modalBusquedaOrigen = !this.modalBusquedaOrigen;
    },
    cancelar() {
      // Lógica para cancelar el proceso
      this.excelFile = null;
      this.excelData = [];
      this.$emit("toggleModalProcesoImportacion");
    }
  }
};
</script>

<style scoped>
.mt-4 {
  margin-top: 16px;
}
</style>
