<template>
  <v-container class="p-0">
    <v-card class="p-2">
      <v-form
        v-model="isFormValid"
        ref="nuevo-traductor-codigo-nomenclador"
        id="nuevo-traductor-codigo-nomenclador"
        @submit.prevent="guardar()"
      >
        <v-card-title class="text-h7 primary--text pl-4 pt-0 pb-1">{{
          title
        }}</v-card-title>
        <v-card-text class="p-0">
          <v-container class="py-0">
            <v-row outlined>
              <v-col cols="12" md="12" class="px-1 py-0">
                <h6 class="subtitle-datos-origen-gecros pb-0">
                  Datos de Gecros
                </h6>
              </v-col>

              <v-col cols="12" md="4" class="py-0 px-1">
                <v-autocomplete
                  id="tipo-nomenclador"
                  label="Tipo de nomenclador"
                  :items="tiposNomenclador"
                  v-model="datosGecros.tipoNomenclador"
                  :rules="rules.required"
                  dense
                  :disabled="disableEdit"
                  :filled="disableEdit"
                  item-text="value"
                  item-value="id"
                  hide-details="auto"
                  class="py-1"
                  outlined
                ></v-autocomplete>
              </v-col>
              <v-col cols="12" md="3" class="py-0 px-1">
                <v-text-field
                  id="codigo-nomenclador"
                  label="Código de práctica"
                  v-model="datosGecros.codigoNomenclador"
                  dense
                  :rules="rules.required"
                  :disabled="disableEdit"
                  :filled="disableEdit"
                  v-mask="'#########'"
                  :loading="searchNomencladorLoading"
                  hide-details="auto"
                  clearable
                  class="py-1"
                  @change="findNomencladorByCodOrNom()"
                  outlined
                >
                  <template v-slot:append-outer>
                    <v-tooltip top max-width="50%">
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon
                          color="primary"
                          v-bind="attrs"
                          v-on="on"
                          v-if="!disableEdit"
                          tabindex="-1"
                          @click="toggleModalBusquedaNom()"
                        >
                          {{ searchIcon }}
                        </v-icon>
                      </template>
                      <span>Buscar por nombre o código</span>
                    </v-tooltip>
                  </template>
                </v-text-field>
              </v-col>
              <v-col cols="12" md="5" class="py-0 px-1">
                <v-textarea
                  id="nombre-practica-gecros"
                  label="Nombre de la práctica"
                  v-model="datosGecros.nombrePractica"
                  filled
                  auto-grow
                  tabindex="-1"
                  rows="1"
                  :loading="searchNomencladorLoading"
                  dense
                  readonly
                  hide-details="auto"
                  outlined
                  class="custom-textarea py-1"
                ></v-textarea>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12" md="12" class="p-0 px-1">
                <h6 class="subtitle-datos-origen-gecros pb-0 px-0">
                  Datos del Origen
                </h6>
              </v-col>
              <!-- Código de práctica -->
              <v-col cols="12" md="3" class="py-0 px-1">
                <v-text-field
                  id="codigo-practica"
                  label="Código de práctica"
                  v-model="datosOrigen.codigoPractica"
                  dense
                  :rules="
                    rules.required.concat(
                      rules.maxLength(datosOrigen.codigoPractica, 20)
                    )
                  "
                  hide-details="auto"
                  :disabled="disableEdit"
                  :filled="disableEdit"
                  class="py-1"
                  outlined
                ></v-text-field>
              </v-col>
              <!-- Nombre de la práctica -->
              <v-col class="py-0 px-1" cols="12" md="7">
                <v-textarea
                  id="nombre-practica"
                  label="Nombre de la práctica"
                  v-model="datosOrigen.nombrePractica"
                  :rules="
                    rules.required.concat(
                      rules.maxLength(datosOrigen.nombrePractica, 250)
                    )
                  "
                  dense
                  rows="1"
                  :readonly="disableEdit"
                  :filled="disableEdit"
                  auto-grow
                  hide-details="auto"
                  class="custom-textarea py-1"
                  outlined

                ></v-textarea>
              </v-col>
              <!-- Área -->
              <v-col cols="12" md="2" class="py-0 px-1">
                <v-autocomplete
                  id="area"
                  label="Área"
                  :items="areas"
                  v-model="datosOrigen.area"
                  item-text="text"
                  item-value="value"
                  :disabled="disableEdit"
                  :filled="disableEdit"
                  :rules="rules.required"
                  dense
                  hide-details="auto"
                  class="py-1"
                  outlined
                ></v-autocomplete>
              </v-col>
              <v-col cols="12" md="5" class="py-0 px-1">
                <v-text-field
                  id="codigo-tipo-nomenclador"
                  label="Código del tipo de nomenclador"
                  v-model="datosOrigen.codigoTipoNomenclador"
                  hide-details="auto"
                  dense
                  :rules="
                    rules.required.concat(
                      rules.maxLength(datosOrigen.codigoTipoNomenclador, 20)
                    )
                  "
                  outlined
                  class="py-1"
                  :disabled="disableEdit"
                  :filled="disableEdit"
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="7" class="py-0 px-1">
                <v-text-field
                  id="nombre-tipo-nomenclador"
                  label="Nombre del tipo de nomenclador"
                  v-model="datosOrigen.nombreTipoNomenclador"
                  dense
                  :rules="
                    rules.required.concat(
                      rules.maxLength(datosOrigen.nombreTipoNomenclador, 250)
                    )
                  "
                  hide-details="auto"
                  class="py-1"
                  outlined
                  :disabled="disableEdit"
                  :filled="disableEdit"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            id="btn-cancelar"
            color="primary"
            outlined
            @click="cerrarDialogo"
            >Cancelar</v-btn
          >
          <v-btn
            id="btn-guardar"
            type="submit"
            form="nuevo-traductor-codigo-nomenclador"
            :disabled="!isFormValid || loadingSaveTraductor || disableEdit"
            :loading="loadingSaveTraductor"
            color="primary"
            >Guardar</v-btn
          >
        </v-card-actions>
        <v-dialog
          v-model="modalBusquedaNom"
          v-if="modalBusquedaNom"
          @keydown.esc="toggleModalBusquedaNom"
          max-width="60rem"
          persistent
        >
          <BusquedaNomencladores
            @toggleModalBusquedaNom="toggleModalBusquedaNom"
            @findNomenclador="findNomenclador"
          ></BusquedaNomencladores>
        </v-dialog>
      </v-form>
    </v-card>
  </v-container>
</template>

<script>
import enums from "@/utils/enums/index.js";
import { mask } from "vue-the-mask";
import rules from "@/utils/helpers/rules";
import { mapActions } from "vuex";
import BusquedaNomencladores from "@/components/modules/prestadores/BusquedaNomencladores.vue";

export default {
  name: "NuevoTraductorCodigoNomenclador",
  components: {
    BusquedaNomencladores
  },
  props: {
    oriId: { type: Number, default: null, required: false },
    disableEdit: { type: Boolean, default: false, required: false },
    origenTraItem: { type: Object, default: null, required: false }
  },
  directives: { mask },
  data() {
    return {
      searchIcon: enums.icons.SEARCH,
      dialog: false,
      title: "Nuevo traductor de código de práctica",
      isFormValid: false,
      rules: rules,
      searchNomencladorLoading: false,
      modalBusquedaNom: false,
      nomCod: null,
      loadingSaveTraductor: false,
      datosOrigen: {
        codigoTipoNomenclador: "",
        nombreTipoNomenclador: "",
        codigoPractica: "",
        nombrePractica: "",
        area: ""
      },
      areas: [
        { text: "Ambulatorio", value: "A" },
        { text: "Internado", value: "I" },
        { text: "Dual", value: "D" }
      ],
      datosGecros: {
        tipoNomenclador: "",
        codigoNomenclador: "",
        nombrePractica: ""
      },
      tiposNomenclador: [],
      practicas: []
    };
  },
  created() {
    this.setTiposNomencladores();
    if (this.origenTraItem != null) {
      this.title = "Editar traductor de código de práctica";
      this.setDatosTraductor(this.origenTraItem);
    }
    if(this.disableEdit) {
      this.title = "Ver traductor de código de práctica";
    }
  },
  methods: {
    ...mapActions({
      setAlert: "user/setAlert",
      getTiposNomencladores: "taxonomy/getTiposNomencladores",
      getNomencladorByCod: "prestadores/getNomencladorByCod",
      saveCodigoOrigenTraductor: "prestadores/saveCodigoOrigenTraductor"
    }),
    async setTiposNomencladores() {
      this.tiposNomenclador = await this.getTiposNomencladores();
    },
    cerrarDialogo() {
      this.$emit("toggleModalNuevoTraductor");
    },
    closeAndReload() {
      this.$emit("closeAndReload");
    },
    setDatosTraductor(item) {
      this.origenTraId = item.origenTraId;
      this.datosOrigen = {
        codigoTipoNomenclador: item.codigoTipoNomenclador,
        nombreTipoNomenclador: item.nombreTipoNomenclador,
        codigoPractica: item.codigoPractica,
        nombrePractica: item.nombrePractica,
        area: item.area.substring(0, 1)
      };
      this.datosGecros = {
        tipoNomenclador: item.nomId,
        codigoNomenclador: item.nomCod,
        nombrePractica: item.nombre
      };
    },
    async findNomencladorByCodOrNom() {
      if (this.datosGecros.codigoNomenclador == null) {
        this.nomCod = null;
        this.datosGecros.nombrePractica = null;
        this.datosOrigen.nombrePractica = null;
        return;
      }
      if (this.datosGecros.codigoNomenclador.length < 3) {
        return;
      }
      try {
        this.searchNomencladorLoading = true;

        const response = await this.getNomencladorByCod(
          this.datosGecros.codigoNomenclador
        );
        if (response != null) {
          this.datosGecros.nombrePractica = response.value;
          this.datosOrigen.nombrePractica = response.value;
          this.datosGecros.codigoNomenclador = response.id;
          this.nomCod = response.id;
        } else {
          this.setAlert({
            message: "No se encontró la práctica",
            type: "error"
          });
          this.datosGecros.nombrePractica = "";
          this.nomCod = null;
        }
      } catch (error) {
      } finally {
        this.searchNomencladorLoading = false;
      }
    },
    findNomenclador(nomenclador) {
      this.datosGecros.codigoNomenclador = nomenclador.id;
      this.datosGecros.nombrePractica = nomenclador.value;
      this.datosOrigen.nombrePractica = nomenclador.value;
      this.nomCod = nomenclador.id;
    },
    toggleModalBusquedaNom() {
      this.modalBusquedaNom = !this.modalBusquedaNom;
    },
    async guardar() {
      this.loadingSaveTraductor = true;
      const data = {
        codigoTipoNomenclador: this.datosOrigen.codigoTipoNomenclador,
        nombreTipoNomenclador: this.datosOrigen.nombreTipoNomenclador,
        codigoPractica: this.datosOrigen.codigoPractica,
        nombrePractica: this.datosOrigen.nombrePractica,
        area: this.datosOrigen.area,
        oriId: this.oriId,
        nomId: this.datosGecros.tipoNomenclador,
        tipoNom: "",
        nomCod: this.datosGecros.codigoNomenclador,
        nombre: this.datosGecros.nombrePractica,
        origenTraId: this.origenTraId,
        procId: this.origenTraItem ? this.origenTraItem.procId : null,
        usuAlta: this.origenTraItem ? this.origenTraItem.usuAlta : null,
        fecAlta: this.origenTraItem ? this.origenTraItem.fecAlta : null
      };
      try {
        const response = await this.saveCodigoOrigenTraductor(data);
        if (response.status == 200) {
          this.setAlert({
            message: "Traductor guardado correctamente",
            type: "success"
          });
          this.closeAndReload();
        }
      } catch (error) {
      } finally {
        this.loadingSaveTraductor = false;
      }
    }
  }
};
</script>

<style scoped>
.subtitle-datos-origen-gecros {
  font-size: 1rem;
  color: rgb(0, 0, 0, 0.6);
}
</style>
